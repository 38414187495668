<div
  class="time-input-field"
  [ngStyle]="{
    width: width + widthUnit
  }"
  [ngClass]="{
    'time-input-field--readonly': !disable && readOnly
  }"
>
  <label
    class="time-input-field__label"
    *ngIf="fieldTitle"
    [ngClass]="{
      'time-input-field__label--required': required
    }"
  >
    {{ fieldTitle }}
  </label>
  <div
    class="time-input-field__input"
    [ngClass]="{
      'time-input-field__input--disabled': disable,
      'time-input-field__input--readonly': !disable && readOnly
    }"
  >
    <input
      type="text"
      class="time-input-field__input-field time-input-field__input-field--hour"
      placeholder="HH"
      [ngModel]="hours"
      (ngModelChange)="setHours($event)"
      (blur)="setHours(padStrings(hours))"
      aria-label="hour"
      [attr.tabindex]="disable ? '-1' : '0'"
    />
    <div class="time-input-field__input-seperator">:</div>
    <input
      type="text"
      class="time-input-field__input-field time-input-field__input-field--mins"
      placeholder="MM"
      [ngModel]="mins"
      aria-label="minute"
      (ngModelChange)="setMins($event)"
      (blur)="setMins(padStrings(mins))"
      [attr.tabindex]="disable ? '-1' : '0'"
    />
  </div>
</div>
