<div class="gravty-text-field" [style]="{ width:'100%'}" >

    <div class="gravty-text-field__input" 
        [style]="{ width: width + widthUnit, 'border-color': focusBorder}" 
        [title]=" type!=='password' ? (value && value.toString().length > 0 ? value : placeholder):''" 
        [ngClass]="{
            'gravty-text-field__input--disabled': disabled,
            'gravty-text-field__input--readonly': readOnly && !disabled,
            'gravty-text-field__input--warning': toolTipMessage && !focusBorderToolTip
        }"
        #InputField
        >
        <div class="gravty-text-field__input-prefix-text" [ngClass]="{ cursorIcon: iconClicked,'gravty-text-field__input-prefix-text__required':required }" *ngIf="
          fieldTitle !== '' && fieldTitle !== undefined && fieldTitle !== null
        ">
            {{ fieldTitle }}:
        </div>
        <input *ngIf="!commaSeparator" [id]="'noscrol_' + randomNumber" class="gravty-text-field__input-field noscroll"
            [placeholder]="!fieldTitle ? placeholder : ''" [type]="type" [(ngModel)]="value" (ngModelChange)="delay==0 ? onUpdate($event):''" (keyup)="validateInput()"
            (keydown)="type == 'number' ? onlyNumber($event) : ''" [readOnly]="readOnly || disableEdit"
            (blur)="handleBlur()" (focus)="setBorderhighlight()"
            [title]="value && type!=='password' && value.toString().length > 0 ? value : (!(fieldTitle && type!=='password') ? placeholder : '')" aria-label="text field" 
            [ngClass]="{
                'gravty-text-field__input-field--pl-0':
                    selectDropdownConfig || fieldTitle,
                'gravty-text-field__suffix-field': suffixText,
                'gravty-text-field__input-field--container': suffixText,
                'gravty-text-field__input-field--alignment': isCentreAlign,
                'gravty-text-field__input-field--warning': toolTipMessage && !focusBorderToolTip
            }" 
            [ngStyle]="{
                'pointer-events': disableInput || disabled ? 'none' : 'auto',
                'background-color': fieldColor,
                'border-color': focusBorder
            }" 
            maxlength="{{ maxLength }}" [disabled]="disabled" (keyup.enter)="enterClicked($event)"
            [attr.tabindex]="disableInput || disabled ? '-1' : '0'"
            (keypress)="restrictDecimal ? ($event.key === '.' ? $event.preventDefault() : null) : null"  
        />
        
        <div class="gravty-text-field__input-suffix-container" *ngIf="suffixText !== '' && suffixText !== undefined && suffixText !== null">
            <span class="gravty-text-field__input-suffix-text" 
          >{{ suffixText }}</span>
        </div>

        <input aria-label="text field" *ngIf="commaSeparator" class="gravty-text-field__input-field noscroll"
            [placeholder]="!fieldTitle ? placeholder : ''" [type]="type" [ngModel]="formatValue(this.value)" (keyup)="validateInput()"
            (keydown)="(type=='number') ? onlyNumber($event) : ''" (ngModelChange)="delay==0 ? onUpdate($event):''"
            [readOnly]="readOnly || disableEdit" [title]="value && value.toString().length > 0 ? value : (!fieldTitle ? placeholder : '')"
            [ngClass]="{
                'gravty-text-field__input-field--pl-0': selectDropdownConfig
            }" 
            maxlength="{{ maxLength }}" (blur)="handleBlur()" (focus)="setBorderhighlight()" [disabled]="disabled"
            gravtyCommaSeparator="commaSeparator ? true : false" 
            (keyup.enter)="enterClicked($event)"
            [attr.aria-label]="formatValue(this.value)"
        />


        <div class="gravty-text-field__input-icon"
            [ngClass]="{ cursorIcon: iconClicked, pointerdisabled: disabled || readOnly }" 
            *ngIf="suffixIcon !== '' && suffixIcon !== undefined && suffixIcon !== null" 
            [ngStyle]="{
                'background-image': 'url(' + suffixIcon + ')',
                width: iconWidth,
                'min-width': iconWidth,
                height: iconHeight,
                'min-height': iconHeight,
                opacity: readOnly ? 0.3 : 1
            }" 
            [attr.tabindex]="readOnly || disabled ? '-1' : '0'" aria-label="icon" role="button"
            (keyup.enter)="iconClick()" (click)="iconClick()">
        </div>
    </div>
  <div class="gravty-text-field__tooltip {{tooltipDirection}}" 
    *ngIf="toolTipMessage.length > 0 && showError">
    <div class="gravty-text-field__tooltip_message"
      [style]="{ width: tooltipWidth + tooltipWidthUnit, height: tooltipHeight + 'px' }">
      {{ toolTipMessage }}
    </div>
  </div>

</div>