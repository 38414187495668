import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CustomTextFieldComponent } from '../custom-text-field/custom-text-field.component';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrl: './search-select.component.less',
})
export class SearchSelectComponent {
  @Input() loader: boolean = false;
  @Input() placeholder: string = 'Select';
  @Input() disabled: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() value: any= '';
  @Input() showError: boolean = true;
  @Input() suffixIcon?: string;
  @Input() options: { key: any; value: string | number; locationValue?: string | number }[] = [
    {
      key: 1,
      value: 100,
    },
    {
      key: 2,
      value: 2,
    },
    {
      key: 2,
      value: 2,
    },
    {
      key: 2,
      value: 2,
    },
    {
      key: 2,
      value: 2,
    },
    {
      key: 2,
      value: 2,
    },
    {
      key: 2,
      value: 2,
    },
  ];
  @Input() width: number = 200;
  @Input() widthUnit: 'px' | '%' | 'em' | 'rem' = 'px';
  @Input() fieldTitle?: string;
  @Input() required: boolean = true;
  @Input() includeErrorMsgPaddingFlag: boolean = true;
  @Input() forModal: boolean = true;
  @Input() highlightOnFocus: string = '';
  @Input() autofocusField: boolean = false;
  @Input() defaultFocus: boolean = false;
  @Input() delay?: number = 300;
  @Input() errorMessage: string = '';
  @Input() forLocation: boolean = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() keyValuePair: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() actionClick: EventEmitter<string> = new EventEmitter<string>();
  
  showDropdown: boolean = false;

  @ViewChild('SelectDropdown') dropdownRef: ElementRef | undefined;
  @ViewChild('inputField') fieldRef: CustomTextFieldComponent | undefined;

  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    document.addEventListener('scroll', (event) => this.preventScroll(event), {
      passive: false,
    });
    document.addEventListener('wheel', (event) => this.preventScroll(event), {
      passive: false,
    });
    document.addEventListener(
      'click',
      (event) => {
        const target = event?.target as HTMLElement;
        if (
          this.showDropdown &&
          !target?.className?.includes('search-select__dropdown')
        ) {
          this.showDropdown = false;
          event.preventDefault();
          event.stopPropagation();
        }
      },
      {
        passive: false,
      }
    );
  }

  preventScroll(event: Event) {
    const target = event?.target as HTMLElement;
    if (
      this.showDropdown &&
      !target?.className?.includes('search-select__dropdown')
    ) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.positionDropdown();
    }
  }

  searchKeyChange(event: string | number) {
    this.search.emit(event.toString());
    this.showDropdown = true;
    this.positionDropdown();
  }
  positionDropdown() {
    if (!this.forModal) {
      return;
    }
    if (this.dropdownRef && this.fieldRef?.clientRect) {
      const fieldRects = this.fieldRef.clientRect[0];
      if (fieldRects) {
        this.dropdownRef.nativeElement.style.top = fieldRects.top + 8 + 'px';
        this.dropdownRef.nativeElement.style.left = fieldRects.left + 'px';
        this.dropdownRef.nativeElement.style.width = fieldRects.width + 'px';
        this.dropdownRef.nativeElement.style.right = fieldRects.right + 'px';
      }
    }
  }
  emitSelection(option: any) {
    this.value = option.key;
    this.valueChange.emit(option.key);
    this.keyValuePair.emit(option);
    this.showDropdown = false;
    // Setting input field value forcefully to avoid native element not being detect changes on selecting same value.
    if (this.fieldRef?.inputFieldRef?.nativeElement) {
      this.fieldRef.inputFieldRef.nativeElement.value = this.displayValue;
    }
  }
  get displayValue(): string {
    return (
      this.options
        ?.find((option) => option.key == this.value)
        ?.value.toString() ?? ''
    );
  }
  emitAction(key: string) {
    this.actionClick.emit(key);
    this.showDropdown = false;
  }
}
