// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let portalURL = window.location.href;
let domain = new URL(portalURL).origin;
let env = 'master'

if(domain.includes('https://merchant-portal.loyaltycloud1.com')) {
	env = 'master';
} else {
	env = 'uat';
}
export const environment = {// this is UAT environemnt.
	production: true,
    themeVariable: 'generic',
	version: '0.0.16',
	contentful: {
		url: 'https://graphql.contentful.com/content/v1/spaces/',
		spaceId: 'gyltuk8cncyq',
		//Content delivery token
		token: '4SIzBWZPRXERQ1omO97iRyB0gyfmZdlUVkksG_3cEOs',
		environments: env
	},
	apiendpoint: {
		url: domain,
		version: 'v1/',
		endpoint: '/bolapi/'
	},
	h_program_id : 17,
	host: function () {
        return this.apiendpoint.url + this.apiendpoint.endpoint + this.apiendpoint.version;
	},
	hostv2: function () {
        return this.apiendpoint.url + this.apiendpoint.endpoint + 'v2/';
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
