import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FilterDataType,
  SuperFilterConfig,
} from '../../interfaces/super-filter-config.interface';
import { SuperFilterUtil } from '../../utils/super-filter.util';

@Component({
  selector: 'app-super-filter',
  templateUrl: './super-filter.component.html',
  styleUrl: './super-filter.component.less',
  encapsulation: ViewEncapsulation.None,
})
export class SuperFilterComponent implements OnInit {
  config: SuperFilterConfig;
  readonly FilterType = FilterDataType;
  getGenericLabels: any

  constructor(
    private dialogRef: MatDialogRef<SuperFilterComponent>,
    @Inject(MAT_DIALOG_DATA) payload: SuperFilterConfig
  ) {
    this.config = structuredClone(payload);
  }

  ngOnInit(): void {
    this.getGenericLabels = JSON.parse(localStorage.getItem('genericLabels') || '{}');
  }

  closeSuperFilter(config?: SuperFilterConfig) {
    this.dialogRef.close({ data: config });
  }
  applySuperFilter() {
    this.closeSuperFilter(this.config);
  }
  resetFilter() {
    this.config = SuperFilterUtil.resetFilters(this.config);
  }
  cloneData(data: any) {
    return structuredClone(data);
  }
  sortFilter(config: any, $event: any) {
    config.sort.value = $event
  }
}
